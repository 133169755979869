import { CommonModule } from '@angular/common';
import { APP_ID, ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LinkField } from '@prismicio/client';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { AnalyticsService } from 'analytics';
import { UserService } from 'auth-data-access';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { LanguageService } from 'language';
import { LinkComponent } from 'link';
import { ProductListItem, ProductService } from 'product-shared';
import { PromotionBannerComponent } from 'promotion-banner';
import { App, createLinkFromString } from 'utils';
import { ProductBoxBase } from '../product-box-base';
import { ProductBoxPriceComponent } from '../product-box-price/product-box-price.component';
import { TableDataProductsComponent } from '../tables/table-data-products/table-data-products.component';
import { TableHomeInternetComponent } from '../tables/table-home-internet/table-home-internet.component';
import { TableMobileComponent } from '../tables/table-mobile/table-mobile.component';
import { TableTvComponent } from '../tables/table-tv/table-tv.component';

@Component({
  selector: 'lib-product-box',
  templateUrl: './product-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SvgComponent,
    LinkComponent,
    PromotionBannerComponent,
    DynamicHooksComponent,
    TableHomeInternetComponent,
    TableDataProductsComponent,
    TableMobileComponent,
    TableTvComponent,
    ProductBoxPriceComponent,
  ],
})
export class ProductBoxComponent extends ProductBoxBase {
  private analytics = inject(AnalyticsService);
  private productService = inject(ProductService);
  private appId = inject(APP_ID, { optional: true });
  private languageService = inject(LanguageService);
  private userService = inject(UserService);
  private readonly userType = toSignal(this.userService.getUserType());
  productItem = input<ProductListItem>(null);
  product = computed(() => this.productItem()?.product);
  promotion = computed(() => this.productItem()?.promotion);
  lightMode = input(false);
  isNavProduct = input(false);
  showLearnMore = input(true);
  typeOfPageShownOn = input('');
  ctaCustomLabel = input('');
  ctaCustomClasses = input('');
  ctaCustomLink = input<LinkField>();
  isYallo = inject(BrandService).isYallo();
  productDetailsLink = computed<LinkField>(() => this.productService.productDetailsLink(this.product()));
  productDetailsLinkLabel = computed(() => {
    if (this.product().product_details_link_label) {
      return this.product().product_details_link_label;
    } else {
      return this.translateService.getTranslation(['productDetailsLinkLabel']);
    }
  });
  ctaConfig = computed(() => {
    this.languageService.currentLanguage();

    const { special_product_type, alternative_link, product_type, product_details_link } = this.product();
    const hfcOrFiber = ['hfc', 'fiber'].includes(product_type);
    const hfcOrFiberInsideNavigation = hfcOrFiber && this.lightMode();
    const hfcOrFiberInsideLineCheckResults = hfcOrFiber && !!this.typeOfPageShownOn();
    const anyProductInsideSpecialDetailPageResults = this.typeOfPageShownOn() === 'special product detail page';
    const specialProductInsideHomePageLineCheckResults =
      special_product_type && this.typeOfPageShownOn() === 'home page'; // HOME MAX, HOME MAX TV

    if (this.ctaCustomLink()) {
      return { link: this.ctaCustomLink() };
    } else if (hfcOrFiberInsideNavigation) {
      return { link: product_details_link };
    } else if (
      this.appId === App.website &&
      (specialProductInsideHomePageLineCheckResults ||
        (alternative_link && !(hfcOrFiberInsideLineCheckResults || anyProductInsideSpecialDetailPageResults)))
    ) {
      return { link: createLinkFromString(alternative_link) as LinkField };
    } else {
      // Default checkout path logic
      return this.productService.buildCheckoutPath(this.product(), this.promotion(), this.productItem()?.lineCheckId);
    }
  });
  queryParams = computed(() => this.ctaConfig().queryParams);
  ctaLink = computed(() => this.ctaConfig().link);
  ctaStyling = computed(() => {
    let label;
    if (this.ctaCustomLabel()) {
      label = this.ctaCustomLabel();
    } else {
      if ((this.product().product_type === 'hfc' || this.product().product_type === 'fiber') && this.lightMode()) {
        label = this.product().product_details_link_label;
      } else {
        label = this.product().cta_button_label || this.translateService.getTranslation(['checkoutButton']);
      }
    }

    let classes;
    if (this.ctaCustomClasses()) {
      classes = this.ctaCustomClasses();
    } else {
      classes = this.promotion() && this.hasDiscountedCost() ? 'featured pulse' : '';
    }

    return {
      label,
      classes,
    };
  });

  public onProductBoxClick() {
    if (this.productService.isLinkToCheckout(this.ctaLink())) {
      this.analytics.addECommerceEvent('add_to_cart', this.product(), this.promotion(), undefined, this.userType());
    }
    if (this.product().alternative_link === '/tv-free-signup') {
      const data = {
        event: 'gtm visitor interaction',
        interaction_name: 'free tv click',
        free_tv_step: 'from tv-free to signup page',
      };
      this.analytics.customEvent(data);
    }

    if (this.isNavProduct()) {
      this.analytics.addToDataLayer({
        event: 'gtm visitor interaction',
        interaction_name: 'mobile nav link click',
        mobile_nav_link_name: `offer - ${this.product().name}`,
      });
    }
  }
}
