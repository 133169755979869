@if (account()) {
  <lib-menu
    [label]="accountLabel()"
    [collapseOnMobile]="collapseOnMobile()"
    [outlined]="true"
    [displayArrow]="false"
    [lightTheme]="isSmallScreenSize()"
    icon="fontawesome/user-regular"
    iconClass="lg:mr-3"
    data-testid="account-button"
    class="hover:bg-(--color-primary-lightened-opacity)"
    [buttonClass]="
      '!border-[--blue-500] lg:!border-[--color-border-primary] max-lg:!bg-gradient-to-r max-lg:!from-[--color-primary-light-from] max-lg:!to-[--color-primary-light-to] max-lg:!to-100%'
    "
    [textClass]="'max-lg:!text-[--blue-500]'"
  >
    <lib-menu-item
      [menuItemLink]="{
        rawUrl: redirectService.getSelfcareUrl(languageService.currentLanguage()),
      }"
    >
      <span class="font-bold"> {{ brand.isYallo() ? 'My yallo' : 'My Lebara' }}</span>
    </lib-menu-item>
    <lib-menu-item>
      <span class="font-bold" (click)="logout()">{{ ['dropdown', 'logout'] | translate }}</span>
    </lib-menu-item>
  </lib-menu>
} @else {
  <lib-link data-testid="nav-login-button" [link]="loginLink()" class="flex items-center justify-evenly">
    <div
      class="border-solid border-[0.5px] border-[--color-border-primary] rounded-full h-12 w-12 lg:w-auto lg:max-w-[10rem] xl:max-w-[8rem] flex justify-center items-center xl:mr-0 px-4 hover:bg-[--color-primary-lightened-opacity]"
    >
      <lib-svg class="white lg:mr-3 hidden lg:block" svg="fontawesome/user-regular" />
      <p class="text-white font-bold text-xs xl:text-base">
        {{ ['login', 'login_button_label'] | translate }}
      </p>
    </div>
  </lib-link>
}
