import { HttpClient } from '@angular/common/http';
import { EventEmitter, inject, Injectable, isDevMode } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RichTextField } from '@prismicio/client';
import { catchError, filter, forkJoin, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { LanguageService } from 'language';
import { checkForEmptyPrismicHtml, CmsService } from 'prismic';
import { StateService, StorageKeys } from 'utils';
import { TRANSLATION_DYNAMIC_VALUES, TRANSLATION_PRISMIC_TAG, TranslationDynamicValues } from './translation.token';

export interface LocalTranslations {
  [key: string]: string | LocalTranslations;
}

export interface PrismicTranslations {
  [documentKey: string]: {
    [key: string]: string | object;
  };
}

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private http = inject(HttpClient);
  private cmsService = inject(CmsService);
  private languageService = inject(LanguageService);
  private stateService = inject(StateService);
  private translationPrismicTag = inject(TRANSLATION_PRISMIC_TAG, { optional: true });
  private config = inject<TranslationDynamicValues>(TRANSLATION_DYNAMIC_VALUES, { optional: true });
  private cacheKeyPrismicTranslation = StorageKeys.PrismicTranslations;
  private cacheKeyLocalTranslation = StorageKeys.LocalTranslations;
  private translationComplete = new EventEmitter<void>();
  public translationCompleteSignal = toSignal(this.onTranslationComplete.pipe(map(() => this.languageService.current)));

  constructor() {
    if (!this.cmsService) {
      this.languageService.onLangChange.pipe(switchMap(() => this.initialize(false))).subscribe();
    } else {
      this.cmsService.clientInitComplete$
        .pipe(
          filter(initComplete => {
            return initComplete;
          }),
          switchMap(() => this.languageService.onLangChange),
          switchMap(() => this.initialize())
        )
        .subscribe();
    }
  }

  public get onTranslationComplete() {
    return this.translationComplete;
  }

  public initialize(usePrismic = true) {
    const currentLang = this.languageService.currentLanguage();
    const prismicKey = `${currentLang}_${this.cacheKeyPrismicTranslation}`;
    const localKey = `${currentLang}_${this.cacheKeyLocalTranslation}`;

    let translations$: Observable<[PrismicTranslations, LocalTranslations]>;

    if (!!this.stateService.get(prismicKey) && !!this.stateService.get(localKey)) {
      translations$ = of([
        this.stateService.get<PrismicTranslations>(prismicKey),
        this.stateService.get<LocalTranslations>(localKey),
      ]);
    } else {
      translations$ = usePrismic
        ? forkJoin([this.preloadPrismicTranslations(), this.preloadLocalTranslations()])
        : forkJoin([of(null), this.preloadLocalTranslations()]);
    }

    return translations$.pipe(
      tap(([prismicTranslations, localTranslations]) => {
        this.stateService.set<PrismicTranslations>(prismicKey, prismicTranslations);
        this.stateService.set<LocalTranslations>(localKey, localTranslations);
        this.translationComplete.next();
      })
    );
  }

  public getPrismicImage(translationPath: string[]): object {
    const currentLang = this.languageService.currentLanguage();
    const prismicKey = `${currentLang}_${this.cacheKeyPrismicTranslation}`;

    const data = this.stateService.get<PrismicTranslations>(prismicKey)?.[translationPath[0]]?.[translationPath[1]];
    if (data && typeof data === 'object') {
      return data;
    }
    return {};
  }

  public getTranslation(translationPath: string[]): string {
    const currentLang = this.languageService.currentLanguage();
    const prismicKey = `${currentLang}_${this.cacheKeyPrismicTranslation}`;
    const localKey = `${currentLang}_${this.cacheKeyLocalTranslation}`;

    // Check if the translation is flat, which means it only exists locally
    if (translationPath.length === 1) {
      return (
        (this.stateService.get<LocalTranslations>(localKey)?.[translationPath[0]] as string) ||
        this.handleMissingTranslation(translationPath)
      );
    }

    // Nested translation: Try Prismic first
    if (translationPath.length === 2) {
      const data = this.stateService.get<PrismicTranslations>(prismicKey)?.[translationPath[0]]?.[translationPath[1]];
      if (data && typeof data === 'string') {
        return data;
      }
    }

    // Fallback to local JSON
    return (
      this.retrieveFromCache(this.stateService.get<LocalTranslations>(localKey), translationPath) ||
      this.handleMissingTranslation(translationPath)
    );
  }

  public replaceToken(
    translations: LocalTranslations | PrismicTranslations | string | null,
    replacements: TranslationDynamicValues
  ): LocalTranslations | PrismicTranslations | string {
    if (!translations) return;

    if (typeof translations === 'string') {
      const tokenPattern = /{{\s*([^}]+?)\s*}}/g;
      return translations.replace(tokenPattern, (match, token) => replacements[token] || match);
    }

    return Object.keys(translations).reduce((acc: LocalTranslations | PrismicTranslations, key) => {
      acc[key] = this.replaceToken(translations[key] as LocalTranslations, replacements);
      return acc;
    }, {});
  }

  private retrieveFromCache(data: LocalTranslations, path: string[]): string | null {
    if (!data || path.length === 0) {
      return this.handleMissingTranslation(path);
    }

    const [key, ...remainingPath] = path;
    const nextData = data[key] as LocalTranslations;

    if (remainingPath.length === 0) {
      return typeof nextData === 'string' ? nextData : this.handleMissingTranslation(path);
    }

    return this.retrieveFromCache(nextData, remainingPath);
  }

  private preloadPrismicTranslations(): Observable<PrismicTranslations> {
    return from(this.cmsService.getAllByTag(this.translationPrismicTag)).pipe(
      take(1),
      map(docs => {
        if (docs.length) {
          const prismicTranslations: PrismicTranslations = {};
          docs.forEach(doc => {
            if (!prismicTranslations[doc.type]) {
              prismicTranslations[doc.type] = {};
            }
            Object.entries(doc.data).forEach(([key, value]) => {
              if (typeof value === 'string') {
                prismicTranslations[doc.type][key] = value;
              } else if (Array.isArray(value) && value[0]?.text) {
                prismicTranslations[doc.type][key] = checkForEmptyPrismicHtml(value as RichTextField);
              } else if (typeof value === 'object' && value?.dimensions) {
                prismicTranslations[doc.type][key] = {
                  alt: value.alt,
                  url: value.url,
                };
              }
            });
          });
          return prismicTranslations;
        } else {
          throw new Error('Documents not found during preload');
        }
      }),
      map(json => this.replaceToken(json, this.config || {}) as PrismicTranslations),
      catchError(error => {
        if (isDevMode()) {
          console.error('Error preloading Prismic translations: ', error);
        }
        return of({});
      })
    );
  }

  private preloadLocalTranslations(): Observable<LocalTranslations> {
    const currentLang = this.languageService.currentLanguage();
    const localJsonPath = `/resources/translations/${currentLang}.json`;
    return this.http.get<LocalTranslations>(localJsonPath).pipe(
      map(json => this.replaceToken(json, this.config || {}) as LocalTranslations),
      catchError(error => {
        if (isDevMode()) {
          console.error('Error fetching translation from local JSON:', error.message);
        }
        return of({});
      })
    );
  }

  private handleMissingTranslation(translationPath: (string | undefined)[]): string {
    const cleanedPath = translationPath.filter(Boolean);

    if (cleanedPath.length === 0) {
      if (isDevMode()) {
        console.warn('Translation path is empty or invalid');
      }
      return '';
    }

    const missingKey = cleanedPath[cleanedPath.length - 1];

    if (!missingKey) {
      if (isDevMode()) {
        console.warn(`Translation not found: '${cleanedPath.join('.')}'`);
      }
      return '';
    }

    const normalizedKey = this.normalizeKey(missingKey);
    if (isDevMode()) {
      console.warn(`Translation not found: '${cleanedPath.join('.')}'`);
    }
    return normalizedKey;
  }

  private normalizeKey(key: string): string {
    // Check if the key looks like a constant (all uppercase with underscores)
    if (/^[A-Z0-9_]+$/.test(key)) {
      // Replace underscores with spaces for constants
      return key.replace(/_/g, ' ');
    }

    return (
      key
        // Replace underscores with spaces, and split camelCase
        .replace(/_/g, ' ')
        // Convert camelCase to separate words only if the case varies
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // Capitalize the first letter of each word
        .replace(/^\w/, c => c.toUpperCase())
    );
  }
}
